body, html {
  margin: 0;
  height: 100vh !important;
  background: #f0f2f5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.login-item .ant-form-item-label label {
  color: white;
}

.react-pdf__Page {
  width: auto !important;
  overflow: auto !important;
  min-width: auto !important;
  min-height: auto !important;
}
